<div class="whatsapp_btn">
  <a href="https://wa.me/+919904290506" target="_blank">
    <img src="../../../assets/images/home/whatsapp.png" alt="whatsapp" />
  </a>
</div>
<div class="work-with-us d-flex flex-md-column flex-row-reverse gap-sm-3 gap-2">
  <div class="scroll-to-top d-flex justify-content-end" (click)="scrollToTop()">
    <a
      class="d-flex align-items-center justify-content-center top"
      [ngClass]="{ glow: isButtonGlowing }"
    >
      <i
        class="bi bi-arrow-up-short"
        [ngClass]="{ arrowUp: isButtonGlowing }"
      ></i>
    </a>
  </div>
  <button
    type="button"
    class="work-with-us-btn position-relative"
    (mouseover)="showPopup()"
    (click)="showPopup()"
  >
    Work With Us
  </button>
  <div
    (mouseleave)="hidePopup()"
    *ngIf="showPopupDetails"
    class="work-with-us-details"
  >
    <div class="popup_details">
      <p class="popup_title">
        Turn your ideas into reality<br />
        with us.
      </p>
      <p class="popup_text">
        Let us know your requirements to book a call with us today!
      </p>
      <a routerLink="requirement">Proceed</a>
    </div>
  </div>
</div>
