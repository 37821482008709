import { Component, OnInit, HostListener } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'nex-work-with-us-btn',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './work-with-us-btn.component.html',
  styleUrls: ['./work-with-us-btn.component.scss'],
  animations: [
    trigger('fadeInUp', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      state('out', style({ opacity: 0, transform: 'translateY(-100%)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate(500),
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0, transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class WorkWithUsBtnComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  isButtonGlowing = false;
  showPopupDetails: boolean = false;

  constructor(private viewportScroller: ViewportScroller) {}

  showPopup(): void {
    this.showPopupDetails = !this.showPopupDetails;
  }

  hidePopup() {
    this.showPopupDetails = false;
  }

  @HostListener('document:scroll', ['$event'])
  onDocumentScroll(event: Event) {
    this.checkScrollPosition();
  }

  private checkScrollPosition() {
    const scrollPosition = this.viewportScroller.getScrollPosition()[1];
    this.isButtonGlowing = scrollPosition > 1000;
  }

  scrollToTop() {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }
}
